<template>
  <div>
    {{ status }}
  </div>
</template>
<script>
export default {
  name: "HealthCheck",
  data() {
    return {
      status: {
        status: "ok"
      },
    };
  },
};
</script>